<template>
  <div>
    <!-- request again reason dialog -->
    <v-dialog v-model="dialog.flag" width="500">
      <v-card class="pb-0">
        <v-card-title class="text-h6 primary_2 white--text" primary-title>
          <p>Request Again Reason</p>
          <v-spacer></v-spacer>
          <v-icon
            large
            color="white"
            @click="dialog.flag = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <div class="mt-3 font-weight-bold">
            Enter a reason
          </div>
          <v-text-field
            outlined
            dense
            height="5"
            v-model="request_reason"
          >
          </v-text-field>
        </v-card-text>  
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="fw-bold px-4 secondary_2 white--text rounded-pill mr-3"
            elevation="0"
            @click="updateDocumentStatus()"
          >
            Request Again
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialog"],
  data() {
    return {
      request_reason: "",
    };
  },
  methods: {
    // request again document
    updateDocumentStatus() {
      const self = this;
      if(self.request_reason != ""){

        console.log("updateDocumentStatus", this.dialog.document_id, this.dialog.action, self.request_reason);

      let form = new FormData();
      form.append("document_id", this.dialog.document_id);
      form.append("document_status", this.dialog.action);
      form.append("rejection_reason", self.request_reason);

      const successHandler = (response) => {
        if (response.data.success) {
          self.$emit('success') 
          self.dialog.flag = false
        }
      };
      const finallyHandler = () => {};
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
        }
    },
  },
};
</script>